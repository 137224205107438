import { useLayoutEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import _ from 'lodash';

import {
    type SwingPosition,
    type UIParameter,
    getLocalizedSwingPositionName,
    localize,
    type Nil,
    Segmentation,
} from '@core';
import type { UIComponentProps } from '../../UIComponent.types';
import UIComponent from '../../UIComponent';
import type { CameraAngles } from '../../../../utils/types/camera';

import * as css from './SwingFoundationsRenderer.floor.css';

interface SwingFoundationsPositionRendererProps {
    readonly position: SwingPosition;
    readonly images: { [key: string]: string } | Nil;
    readonly segmentation: Segmentation | Nil;
    readonly parameterProps: UIComponentProps<UIParameter>[];
}

export function SwingFoundationsRenderer({ position, images, parameterProps }: SwingFoundationsPositionRendererProps) {
    const title = getLocalizedSwingPositionName(position);
    const subtitle = localize('module_type.swing_foundations');
    const angle: CameraAngles = position === 'p2' ? 'down_the_line' : 'face_on';

    // Ideal height for a custom module parameter
    const MODULE_HEIGHT = 80;
    // The whole width of the page
    const PAGE_WIDTH = 1690;

    const [dimensions, setDimensions] = useState({ columns: 3, rows: 1, maxItems: 3 });
    const [ref, { width, height }] = useMeasure();

    useLayoutEffect(() => {
        const columns = width >= PAGE_WIDTH ? 4 : width >= PAGE_WIDTH / 2 ? 3 : 2;
        const rowHeight = MODULE_HEIGHT;
        const rows = Math.min(Math.floor(height / rowHeight), 4);
        const maxItems = rows * columns;

        setDimensions({ columns, rows, maxItems });
    }, [width, height]);

    return (
        <div className={css.root} ref={ref}>
            <div className={css.top}>
                <h3 className={css.title}>{title}</h3>
                <p className={css.subtitle}>{subtitle}</p>
            </div>
            <div className={css.body}>
                <PositionImage images={images} angle={angle} position={position} />
                <FloorParametersLayout parameters={parameterProps} dimensions={dimensions} />
            </div>
        </div>
    );
}

function FloorParametersLayout({
    parameters,
    dimensions,
}: {
    parameters: UIComponentProps<UIParameter>[];
    dimensions: { columns: number; rows: number; maxItems: number };
}) {
    return (
        <div
            className={css.parametersLayout({
                columns: dimensions.columns as 3 | 4,
                rows: dimensions.rows as 1 | 2 | 3 | 4 | 5,
            })}
        >
            {_(parameters)
                .take(dimensions.maxItems)
                .map((props) => (
                    /** We need to properly unify how parameters are rendered before finishing this part */
                    <UIComponent key={props.currentNode.id} {...props} />
                ))
                .value()}
        </div>
    );
}

function PositionImage({
    images,
    angle = 'down_the_line',
    position,
}: {
    images: { [key: string]: string } | Nil;
    angle?: CameraAngles;
    position: SwingPosition;
}) {
    const frameUrl = images?.[`${angle}.${position}`];

    return (
        <div className={css.frame({ showFallback: !!frameUrl })}>
            {frameUrl && <img src={frameUrl} className={css.frameImg} alt="" />}
        </div>
    );
}
