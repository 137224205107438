import { Spinner, SystemMessageBanner } from '@ui';
import { useCommunicationSocketStore } from '../state/communicationSocketStore';
import { ScreenTitle } from '../components/ScreenTitle';

import * as css from './screens.css';
import { Outlet } from 'react-router';
import { useSession } from '../utils/hooks/useSession';
import ConnectionPendingScreen from './ConnectionPendingScreen';

export default function KioskLayout() {
    /**
     * Used here to be able to display the "Reconnecting... " message for the user
     */
    const isConnected = useCommunicationSocketStore((state) => state.isConnected);

    const { user, isLoading, cameraConfig } = useSession();

    if (!isLoading && !user) {
        return <ConnectionPendingScreen />;
    }

    if (isLoading || !cameraConfig) {
        return <Spinner size="l" isAbsolute />;
    }

    return (
        <div className={css.root}>
            <ScreenTitle title="Elva Golf — Kiosk" />
            <SystemMessageBanner message="Disconnected, trying to reconnect..." isVisible={!isConnected} />

            <Outlet />
        </div>
    );
}
