import { Fragment, type ReactElement } from 'react';
import _ from 'lodash';
import { Label, ReferenceLine, XAxis } from 'recharts';

import type { Segmentation } from '@core';
import { constants } from '@core';

interface ValidPosition {
    label: string;
    value: number;
}

interface ReferenceLineProps {
    positions: ValidPosition[];
    allowedBottomLabels: string[];
    axisKey: string;
    referenceFrame?: number;
    hasComparison: boolean;
}

const style = {
    color: '#94A3B8',
    WebkitFontSmoothing: 'antialiased',
    fontSize: 10,
    fontWeight: 200,
    letterSpacing: '0.05em',
    fontFamily: 'Inter',
    backgroundColor: 'white',
};

const renderCurrentReferenceLines = ({
    positions,
    allowedBottomLabels,
    axisKey,
    referenceFrame,
    hasComparison,
}: ReferenceLineProps): ReactElement[] => {
    if (!referenceFrame) {
        throw new Error('Reference frame is required for current reference lines');
    }

    return positions.map(({ label, value }) => {
        const showMilliseconds = _.includes(allowedBottomLabels, label);

        const milliseconds = showMilliseconds
            ? Math.round(((value - referenceFrame) / constants.CAMERA_FPS) * 1000)
            : 0;
        const sign = milliseconds >= 0 ? '+' : '';

        // Comparison graph data is synced to P7, meaning that other positions are most definitely not synced.
        // We'll fade out the other position labels to indicate that they shouldn't be considered "synced".
        const unsyncedPositionLabel = hasComparison && label !== 'P7';

        return (
            <ReferenceLine
                key={label}
                x={value}
                strokeDasharray="3 3"
                yAxisId={axisKey}
                stroke={style.color}
                opacity={0.4}
            >
                <Label
                    value={label}
                    offset={16}
                    position="top"
                    style={style}
                    opacity={unsyncedPositionLabel ? 1 / 3 : 1}
                />
                {showMilliseconds && (
                    <Label value={`${sign}${milliseconds}ms`} offset={16} position="bottom" style={style} />
                )}
            </ReferenceLine>
        );
    });
};

const getValidPositions = (segmentation: Segmentation): ValidPosition[] => {
    return _.entries(segmentation)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => ({
            label: key,
            value,
        }));
};

export const renderXAxis = ({
    isQuickAnalysis,
    segmentation,
    axisKey,
}: {
    isQuickAnalysis: boolean;
    segmentation: {
        current: Segmentation;
        comparison: Segmentation | null;
    };
    axisKey: string;
}): ReactElement => {
    const frameNumbers = _.values(segmentation.current);
    const domain: [number, number] = [_.first(frameNumbers) as number, _.last(frameNumbers) as number];
    const allowedBottomLabels: string[] = ['P1', 'P2', 'P3', 'P4', 'P7', 'P9', 'P10'];

    const validPositions = getValidPositions(segmentation.current);

    const referenceLines = renderCurrentReferenceLines({
        positions: validPositions,
        allowedBottomLabels,
        axisKey,
        referenceFrame: segmentation.current['P7'],
        hasComparison: segmentation.comparison !== null,
    });

    return (
        <Fragment>
            {referenceLines}
            {isQuickAnalysis && (
                <XAxis
                    visibility="hidden"
                    dataKey="frame"
                    height={0}
                    scale="time"
                    type="number"
                    domain={domain}
                    ticks={frameNumbers}
                    tick={false}
                />
            )}
        </Fragment>
    );
};
