import { useMemo } from 'react';

import type { UIGraphLine, StaticL10nID, Analysis, Segmentation } from '@core';
import { localize } from '@core';
import { Graph } from '@ui';
import type { ImplementationOf, UIComponentProps } from '../../UIComponent.types';
import ModuleBaseKiosk from '../../../ModuleBase/ModuleBase.kiosk';
import ModuleBaseFloor from '../../../ModuleBase/ModuleBase.floor';
import { useAnalysisByID, useGlobalStore, useSelectedAnalysis } from '../../../../state/globalStore';
import { getSegmentation, graphDataFromLines, parseAndGroupLines } from './Graph.utils';

export const GraphImplementation: ImplementationOf<'graph'> = ({ node, children: _children, currentDevice }) => {
    const children = _children as UIComponentProps<UIGraphLine>[];
    const currentFrame = useGlobalStore((state) => state.currentFrame);
    const activePosition = useGlobalStore((state) => state.activePosition);
    const comparisonSwingID = useGlobalStore((state) => state.comparisonSwingID);

    // TODO: Should we toggle `transition` and `apex` on/off via metadata or node.id?
    const includeTransitionPoints = node.id === 'graph.transition_sequence';
    const includeApexPoints = node.id === 'graph.timing_sequence';

    const shouldUseLatestSwing = currentDevice === 'floor';
    const selectedAnalysis: Analysis | null = useSelectedAnalysis(shouldUseLatestSwing) || null;
    const comparisonAnalysis: Analysis | null = useAnalysisByID(comparisonSwingID) || null;

    const lines = useMemo(() => parseAndGroupLines(children), [children]);

    const segmentation = useMemo(() => {
        return {
            current: selectedAnalysis ? getSegmentation(selectedAnalysis) : null,
            comparison: comparisonAnalysis ? getSegmentation(comparisonAnalysis) : null,
        };
    }, [selectedAnalysis, comparisonAnalysis]);

    const graphData = useMemo(
        () =>
            graphDataFromLines({
                linesPerUnit: lines,
                analysis: selectedAnalysis,
                comparisonAnalysis,
                segmentation: segmentation.current,
                comparisonSegmentation: segmentation.comparison,
                extras: {
                    includeTransitionPoints,
                    includeApexPoints,
                },
            }),
        [lines, selectedAnalysis, comparisonAnalysis, segmentation, includeTransitionPoints, includeApexPoints],
    );

    const isPending = !graphData;

    switch (currentDevice) {
        case 'kiosk': {
            return (
                <ModuleBaseKiosk
                    title={node.name?.value}
                    subtitle={localize(`${node.categories.graph_type}` as StaticL10nID, 'graph_type.fallback')}
                    isPending={isPending}
                >
                    {!isPending && (
                        <Graph
                            data={graphData}
                            lines={lines}
                            segmentation={segmentation as { current: Segmentation; comparison: Segmentation | null }} // We always have a `current` segmentation by this point.
                            device="kiosk"
                            currentFrame={currentFrame}
                            activePosition={activePosition}
                        />
                    )}
                </ModuleBaseKiosk>
            );
        }
        case 'floor': {
            return (
                <ModuleBaseFloor
                    title={node.name?.value}
                    subtitle={localize(`${node.categories.graph_type}` as StaticL10nID, 'graph_type.fallback')}
                    showFallback={isPending}
                >
                    {!isPending && (
                        <Graph
                            data={graphData}
                            lines={lines}
                            segmentation={segmentation as { current: Segmentation; comparison: Segmentation | null }} // We always have a `current` segmentation by this point.
                            device="floor"
                        />
                    )}
                </ModuleBaseFloor>
            );
        }
    }
};
