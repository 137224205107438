import { Outlet, useParams } from 'react-router';
import { useEffect } from 'react';

import Navigation from '../components/Navigation';
import { useUIComponentValues } from '../state/globalStore';
import UIComponentsLayout from '../components/UIComponentsLayout';
import Scrubber from '../components/Scrubber/Scrubber';
import { useUserSettingsStore } from '../state/userSettingsStore';
import OldActivityTopBar from '../components/OldActivityTopBar';
import { ScreenTitle } from '../components/ScreenTitle';

import * as css from './screens.css';
import { useActivityContext } from '../utils/contexts/ActivityContext';
import { useBeastSocketConnection } from '../utils/hooks/useBeastSocketConnection';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import { FullAnalysisListener } from '../realtime/FullAnalysisListener';
import { BeastListener } from '../realtime/BeastListener';
import { RelayConnection } from '../realtime/RelayConnection';

function Details() {
    const { isCurrentActivity } = useActivityContext();
    const [activeLayout, uiNodes] = useUserSettingsStore((state) => [state.activeLayout, state.uiNodeTree]);
    const userSettingsLoaded = activeLayout && uiNodes;
    const values$ = useUIComponentValues();

    return (
        <div className={css.root}>
            <ScreenTitle title="Elva Golf — Kiosk" />

            {!isCurrentActivity && <OldActivityTopBar />}

            <div className={css.screen}>
                <Navigation isActivityReadOnly={!isCurrentActivity} />
                {userSettingsLoaded && (
                    <UIComponentsLayout
                        uiNodeTree={uiNodes}
                        layout={activeLayout}
                        currentDevice="kiosk"
                        values$={values$}
                    />
                )}
                <Scrubber />
            </div>
            <Outlet />
        </div>
    );
}

export default function ActivityDetails() {
    const { id } = useParams();
    const { showActivity, showCurrentActivity } = useActivityContext();
    const { boothSessionDetails } = useBoothSession();
    const hasEndpoints = boothSessionDetails?.beastEndpoint && boothSessionDetails.relayEndpoint;

    useEffect(() => {
        if (id === 'current') {
            showCurrentActivity();
        } else {
            showActivity(Number(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            <Details />
            <Outlet />
            {hasEndpoints && <WebsocketInit />}
        </>
    );
}

function WebsocketInit() {
    useBeastSocketConnection();
    return (
        <>
            <BeastListener />
            <RelayConnection />
            <FullAnalysisListener />
        </>
    );
}
