import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import { Button, typography } from '@ui';
import { useSession } from '../../utils/hooks/useSession';
import EmailSentScreen from '../EmailSentScreen';
import Container from '../../components/Container';
import Input from '../../components/Input';
import Card from '../../components/Card';

import * as css from './ResetPasswordScreen.css';

interface FormValues {
    email: string;
}

const ResetPasswordScreen = () => {
    const { register, handleSubmit } = useForm<FormValues>();
    const navigate = useNavigate();
    const location = useLocation();
    const { initiateResetPassword, loginWithToken } = useSession();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState<string | null>(null);

    useEffect(() => {
        const { hash } = location;
        if (hash) {
            const params = new URLSearchParams(hash);
            const accessToken = params.get('access_token');
            const refreshToken = params.get('refresh_token');

            if (accessToken && refreshToken) {
                console.log('LOGGING IN WITH TOKEN');
                void loginWithToken(accessToken, refreshToken);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onSubmit = async (formValues: FormValues) => {
        const email = formValues.email;
        setIsLoading(true);
        const error = await initiateResetPassword(formValues.email);

        if (error) {
            return console.log(error.message);
        }

        setEmail(email);
    };

    if (email) {
        return <EmailSentScreen email={email} />;
    }

    return (
        <Container>
            <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={css.formContainer}>
                        <h2 className={typography({ variant: 'h2' })}>Reset your password</h2>
                        <p className={typography({ variant: 'p' })}>
                            Please enter your email address, and we will send you instructions to reset your password.
                        </p>
                        <Input
                            label="Your email"
                            type="email"
                            {...register('email', { required: 'Email is required' })}
                            placeholder="Enter your email"
                        />
                        <div className={css.buttons}>
                            <Button type="button" variant="secondary" onClick={() => navigate('/')}>
                                Cancel
                            </Button>
                            <Button type="submit" isLoading={isLoading}>
                                Send reset link
                            </Button>
                        </div>
                    </div>
                </form>
            </Card>
        </Container>
    );
};

export default ResetPasswordScreen;
