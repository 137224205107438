import { Unit, unitConversionMap } from '@core';

export const formatValue = (value: number | undefined, unit: Unit, showUnitSymbol: boolean) => {
    if (typeof value !== 'number') {
        return '— ';
    }

    const x = unitConversionMap[unit]?.(value) ?? { value, unit };
    return `${x.value ?? '— '}${showUnitSymbol ? x.symbol : ''}`;
};
