import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { AnimatePresence } from 'motion/react';

import { localize, type Tag } from '@core';
import { Button, colors, EditableTag, Screen } from '@ui';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import { TagModal } from './TagModal';
import { ActivityContext } from '../../utils/contexts/ActivityContext';
import { PropTypes } from './TagSelection.types';

import * as css from './TagSelection.css';

export default function TagSelection({ supabase }: PropTypes) {
    const [isEditable, setIsEditable] = useState(false);
    const [modalOpen, setModalOpen] = useState<'add' | 'edit' | undefined>();
    const [editTag, setEditTag] = useState<Tag | undefined>();
    const navigate = useNavigate();

    const activityContext = useContext(ActivityContext);

    const [availableTags, activityTags] = useUserSettingsStore((state) => [state.availableTags, state.activityTags]);

    const [selectedTags, setSelectedTags] = useState<Tag[]>(activityTags ?? []);

    const handleSave = async () => {
        const activityTagIds = activityTags?.map((tag) => tag.id).sort();
        const selectedTagIds = selectedTags.map((tag) => tag.id).sort();
        const tagsChanged = JSON.stringify(activityTagIds) !== JSON.stringify(selectedTagIds);

        // When new tags are selected, a new activity should be created created
        if (tagsChanged) {
            activityContext?.changeTags(selectedTags);
        }
    };

    const onEditClick = (tag: Tag) => {
        setEditTag(tag);
        setModalOpen('edit');
    };

    const onClick = (tag: Tag) => {
        setSelectedTags(() => {
            if (selectedTags.some((t) => t.id === tag.id)) {
                return selectedTags.filter((t) => t.id !== tag.id);
            }
            return [...selectedTags, tag];
        });
    };

    return (
        <Screen.Root
            title={localize('swing_foundations_editor.title')}
            header={{
                static: {
                    end: (
                        <>
                            <Button variant="secondary" onClick={() => navigate(-1)}>
                                {localize('generic_ui.cancel')}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={async () => {
                                    // TODO: Save async, display `isLoading` on the button.
                                    await handleSave();
                                    navigate(-1);
                                }}
                            >
                                {localize('generic_ui.save')}
                            </Button>
                        </>
                    ),
                },
            }}
        >
            <Screen.Column span="20/20">
                <div className={css.root}>
                    <div className={css.content}>
                        <div className={css.tags}>
                            {availableTags.map((tag) => {
                                const isSelected = selectedTags.some((t) => t.id === tag.id);

                                return (
                                    <EditableTag
                                        key={tag.id}
                                        onClick={() => {
                                            if (isEditable) {
                                                onEditClick(tag);
                                            } else {
                                                onClick(tag);
                                            }
                                        }}
                                        isSelected={isSelected}
                                        isEditable={isEditable}
                                    >
                                        {tag.name}
                                    </EditableTag>
                                );
                            })}
                        </div>

                        <div className={css.buttons}>
                            <Button size="medium" variant="primary" icon="plus" onClick={() => setModalOpen('add')}>
                                {localize('generic_ui.add_tag')}
                            </Button>
                            <Button
                                size="medium"
                                variant={isEditable ? 'tertiary' : 'secondary'}
                                icon="pencil"
                                iconColor={isEditable ? colors.blue[500] : colors.current}
                                onClick={() => setIsEditable((prev) => !prev)}
                            >
                                {localize('generic_ui.edit_tags')}
                            </Button>
                        </div>
                    </div>
                    <AnimatePresence>
                        {modalOpen && (
                            <TagModal
                                modalOpen={modalOpen}
                                setModalOpen={setModalOpen}
                                editTag={editTag}
                                setEditTag={setEditTag}
                                setSelectedTags={setSelectedTags}
                                selectedTags={selectedTags}
                                supabase={supabase}
                            />
                        )}
                    </AnimatePresence>
                </div>
            </Screen.Column>
        </Screen.Root>
    );
}
