// Legend is rendered on Floor only

import _ from 'lodash';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { typography } from '../../../typography';
import type { LegendPayload } from '../Graph.types';

import * as css from '../Graph.css';

export const renderLegend = (payload?: LegendPayload[]) => (
    <ul className={css.legendLayout}>
        {!_.isEmpty(payload) &&
            _.map(payload, (entry, index) => {
                // Render custom label if it was provided
                const value = entry.payload?.label || entry.value;
                const type = entry.payload?.legendType || 'none';

                if (type === 'none') {
                    return null;
                }

                return (
                    <li key={`${entry.value}_${index}`} className={css.legend({ inactive: entry.inactive })}>
                        <span
                            className={typography({
                                variant: 'h4',
                            })}
                        >
                            {value}
                        </span>
                        <div
                            className={css.legendLine({ dotted: false })}
                            style={assignInlineVars({
                                [css.legendLineColor]: entry.color,
                            })}
                        />
                    </li>
                );
            })}
    </ul>
);
