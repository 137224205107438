import { useNavigate } from 'react-router';

import { Button, typography } from '@ui';
import Container from '../../components/Container';
import Card from '../../components/Card';
import VStack from '../../components/VStack';

type EmailSentScreenProps = {
    email: string;
};

const EmailSentScreen = ({ email }: EmailSentScreenProps) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Card>
                <VStack spacing={20} style={{ width: '350px' }}>
                    <h3 className={typography({ variant: 'h2' })}>Check Your Email</h3>
                    <p className={typography({ variant: 'p' })}>
                        An email has been sent to <strong>{email}</strong> with detailed instructions to reset your
                        password. Please follow the steps provided to complete the process.
                    </p>
                    <p>Once your password has been reset, you can return to the home screen and log in as usual.</p>
                    <Button onClick={() => navigate('/')}>Return to login</Button>
                </VStack>
            </Card>
        </Container>
    );
};

export default EmailSentScreen;
