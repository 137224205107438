import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { sub } from 'date-fns';
import DatePicker from 'react-datepicker';
import { Link, useNavigate } from 'react-router';

import { formatDate, formatTime, type Club } from '@core';
import { typography, Spinner, Screen, Button } from '@ui';
import { ArrowClockwise } from '../../components/icons/ArrowClockwise';
// import { FolderStar } from '../../components/icons/FolderStar';
import { Club as ClubIcon } from '../../components/icons/Club';
import { SearchInput } from '../../components/SearchInput/SearchInput';
// import { Star } from '../../components/icons/Star';
import { Clock } from '../../components/icons/Clock';
import { ClubTableModal } from '../../components/modals/ClubTableModal';
import type { ActivitiesListProps, ActivityOverViewItemProps } from './ActivityOverview.types';
import { useActivities } from '../../queries/useActivities';
import { useActivityContext } from '../../utils/contexts/ActivityContext/ActivityContext';

import * as css from './ActivityOverview.css';

export function ActivityOverview() {
    const [filter, setFilter] = useState('');
    const initialStartDate = sub(new Date().setHours(0, 0, 0), { years: 1 });
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [favoritesOn, setFavoritesOn] = useState(false);
    const [clubFilter, setClubFilter] = useState<Club | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const { isLoadingActivities, activities } = useActivities();
    const { currentActivityId, swings } = useActivityContext();
    const navigate = useNavigate();

    // Add swings to current activity
    const currentActivity = activities?.find((activity) => activity.id === currentActivityId);
    if (currentActivity) {
        currentActivity.swing_count = swings.length;
    }

    // TODO(at): Filter in the db
    // Filters by date and accounts for different timezones
    const dateRangeActivities = activities?.filter((activity) => {
        const parsedDate = new Date(activity.start_time);
        const activityDate = new Date(
            Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 0, 0, 0),
        );
        return (!startDate || activityDate >= startDate) && (!endDate || activityDate <= endDate);
    });

    // TODO(at): Filter in the db
    // Filters by search
    const filteredActivities =
        dateRangeActivities?.filter((activity) => {
            const hasFavorites = favoritesOn ? (activity?.favorite_swings_count ?? 0) > 0 : true;

            const matchesClubFilter = clubFilter ? activity.club_id === clubFilter.id : true;

            const matchesFilter =
                filter.length > 0
                    ? activity?.tags?.some((tag) => tag.name?.toLowerCase().includes(filter?.toLowerCase()))
                    : true;

            return hasFavorites && matchesClubFilter && matchesFilter;
        }) || []; // Default to an empty array if undefined

    const showNoResultsMessage = !activities || activities?.length <= 0;

    const resetFilter = () => {
        setClubFilter(undefined);
        setFavoritesOn(false);
        setFilter('');
        setStartDate(initialStartDate);
        setEndDate(new Date());
    };

    const handleClose = () => {
        navigate('../');
    };

    return (
        <Screen.Root
            title="Activity overview"
            header={{
                static: {
                    end: (
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    ),
                },
                fixed: undefined,
            }}
        >
            <Screen.Column span="6/20" sticky>
                <div className={css.sidebar}>
                    <div className={css.filterBox}>
                        <div className={css.filterBoxTopBottom}>
                            <p
                                className={typography({
                                    variant: 'h2',
                                })}
                            >
                                Activities
                            </p>
                            <p>{activities?.length ?? '-'}</p>
                        </div>
                        <div className={css.datesRange}>
                            <p
                                className={typography({
                                    variant: 'h4',
                                })}
                            >
                                Date range
                            </p>
                            <div className={css.dates}>
                                <div className={css.dateBox}>
                                    <p
                                        className={typography({
                                            variant: 'h4',
                                        })}
                                    >
                                        From
                                    </p>
                                    <DatePicker
                                        className={css.date}
                                        selected={startDate}
                                        onChange={(date) => {
                                            if (!date) {
                                                return;
                                            }
                                            const utcDate = new Date(
                                                Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
                                            );
                                            setStartDate(utcDate);
                                        }}
                                    />
                                </div>
                                <div className={css.dateBox}>
                                    <p
                                        className={typography({
                                            variant: 'h4',
                                        })}
                                    >
                                        To
                                    </p>
                                    <DatePicker
                                        className={css.date}
                                        selected={endDate}
                                        onChange={(date) => {
                                            if (!date) {
                                                return;
                                            }
                                            const utcDate = new Date(
                                                Date.UTC(
                                                    date.getFullYear(),
                                                    date.getMonth(),
                                                    date.getDate(),
                                                    23,
                                                    59,
                                                    59,
                                                ),
                                            );
                                            setEndDate(utcDate);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <SearchInput value={filter} onChange={setFilter} placeholder="Filter by keywords" />
                        <div className={css.filterBoxTopBottom}>
                            {/*<button
                                onClick={() => setFavoritesOn((val) => !val)}
                                className={css.toggleIconButton({ active: favoritesOn })}
                            >
                                <FolderStar color="inherit" />
                                <p
                                    className={typography({
                                        variant: 'h4',
                                    })}
                                >
                                    Favorites
                                </p>
                            </button>*/}
                            <button onClick={() => setModalOpen(true)} className={css.borderIconButton}>
                                <ClubIcon />
                                <p
                                    className={typography({
                                        variant: 'h3',
                                    })}
                                >
                                    {clubFilter ? clubFilter.long_name : 'Filter by club'}
                                </p>
                            </button>
                            <button onClick={resetFilter} className={css.borderIconButton}>
                                <ArrowClockwise />
                                <p
                                    className={typography({
                                        variant: 'h4',
                                    })}
                                >
                                    Reset Filters
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </Screen.Column>
            <Screen.Column span="14/20">
                <div className={css.activities}>
                    {/*<div className={css.favorites}>
                        <div className={css.favoriteCard}>
                            <p
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                All favorite swings
                            </p>
                            <div className={css.tags}>
                                <div className={css.tag}>
                                    <p>Swing</p>
                                </div>
                                <div className={css.tag}>
                                    <p>{numberOfFavoriteSwings}</p>
                                    <Star />
                                </div>
                            </div>
                        </div>
                        <div className={css.favoriteCard}>
                            <p
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                ELVA comparison swings
                            </p>
                            <div className={css.tag}>
                                <p>0</p>
                                <Star />
                            </div>
                        </div>
                    </div>*/}

                    <ActivitiesList
                        isLoading={isLoadingActivities}
                        showNoResultsMessage={showNoResultsMessage}
                        activities={filteredActivities}
                        currentActivityId={currentActivityId}
                    />
                </div>
                <ClubTableModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    onClubClick={(club) => {
                        if (clubFilter?.id === club.id) {
                            setClubFilter(undefined);
                        } else {
                            setClubFilter(club);
                        }
                        setModalOpen(false);
                    }}
                    selectedClub={clubFilter}
                />
            </Screen.Column>
        </Screen.Root>
    );
}

/**
 * The list of activities
 */
export const ActivitiesList = ({
    activities,
    showNoResultsMessage,
    isLoading,
    currentActivityId,
}: ActivitiesListProps) => {
    return (
        <div className={css.activitiesList}>
            {isLoading && (
                <div className={css.centered}>
                    <Spinner size="m" />
                </div>
            )}
            {!isLoading && showNoResultsMessage && (
                <div className={css.centered}>
                    <p
                        className={typography({
                            variant: 'p',
                        })}
                    >
                        No results
                    </p>
                </div>
            )}

            {activities?.map((activity, index) => {
                const isCurrentActivity = activity.id === currentActivityId;
                return (
                    <ActivityOverViewItem
                        key={activity.id}
                        isCurrentActivity={isCurrentActivity}
                        activity={activity}
                        index={index}
                        starredSwingsCount={activity.favorite_swings_count ?? 0}
                        noOfSwings={activity.swing_count ?? 0}
                    />
                );
            })}
        </div>
    );
};

const ActivityOverViewItem = ({
    isCurrentActivity = false,
    activity,
    index,
    starredSwingsCount,
    noOfSwings,
}: ActivityOverViewItemProps) => {
    return (
        <Link
            key={activity.id}
            className={`${css.activity} ${css.activityItem}`}
            style={{ animationDelay: `${index * 0.03}s` }}
            to={isCurrentActivity ? '/kiosk/current' : `/kiosk/${activity.id}`}
        >
            <div className={css.time}>
                <p
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {formatDate(activity.start_time)}
                </p>
                <Clock width={16} variant="border" />
                <p
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {formatTime(activity.start_time)}
                </p>
            </div>
            <div className={css.tags}>
                {isCurrentActivity && <div className={css.greenTag}>CURRENT ACTIVITY</div>}
                {activity.club_id && <div className={css.tag}>{activity?.club?.short_name}</div>}
                <div className={css.swingCountTag}>
                    <div className={css.numberOfSwings}>{noOfSwings} swings</div>
                    {/*<div className={css.numberOfStarredSwings}>
                        {starredSwingsCount}
                        <Star />
                    </div>*/}
                </div>
                {activity.tags?.map((tag) => {
                    return (
                        <div key={tag.id} className={css.tag}>
                            {tag.name}
                        </div>
                    );
                })}
            </div>
        </Link>
    );
};
