import { Fragment, useCallback, useMemo, useState } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';
import _ from 'lodash';
import {
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    ComposedChart,
    Line,
    ReferenceLine,
    ReferenceDot,
    Legend,
} from 'recharts';

import type { Device, Segmentation, Unit } from '@core';
import { localize } from '@core';
import { colors } from '../../colors';
import { typography } from '../../typography';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { renderXAxis } from './helpers/renderXAxis';
import { formatValue } from './helpers/formatValue';
import { renderLegend } from './helpers/renderLegend';
import type { LegendPayload, LinesPerUnit } from './Graph.types';

import * as css from './Graph.css';

type MeasurementValue = {
    current: number | null;
    comparison: number | null;
};

type GraphData = {
    dataPoints: Array<Record<string, MeasurementValue | null>>;
    isQuickAnalysis: boolean;
    apex: Record<string, { frame: number; value: number }>;
    transition: Record<string, { frame: number; value: number }>;
};

type GraphProps = {
    data: GraphData;
    lines: LinesPerUnit;
    segmentation: { current: Segmentation; comparison: Segmentation | null };
    device: Device;
    currentFrame?: number;
    activePosition?: number;
};

const defaultColors = ['#2196F3', '#FF9800', '#4CAF50', '#E91E63', '#9C27B0', '#00BCD4', '#FFC107'];

export function Graph({
    data: { dataPoints, apex, transition, isQuickAnalysis },
    lines,
    segmentation,
    device,
    currentFrame,
    activePosition = 1,
}: GraphProps) {
    const [filteredDataKeys, setFilteredDataKeys] = useState<string[]>([]);
    const [ref, isVisible] = useIntersectionObserver({ threshold: 0.2 });
    const isInteractive = typeof currentFrame === 'number';

    const onClickLegend = useCallback((dataKey: string) => {
        setFilteredDataKeys((prev) =>
            _.includes(prev, dataKey) ? _.filter(prev, (item) => item !== dataKey) : [...prev, dataKey],
        );
    }, []);

    const { flattenedLines, unit } = useMemo(() => {
        const flattenedLines = _.flatMap(lines, (lineArray, unit) =>
            _.map(lineArray, (line) => ({ ...line, unit })).sort((a, b) => a.id.localeCompare(b.id)),
        );
        const unit = _.keys(lines)[0];

        return { flattenedLines, unit };
    }, [lines]);

    // Comparison for apex- and transition charts is too chaotic.
    const showComparisonData = _.isEmpty(apex) && _.isEmpty(transition);

    const isFloor = device === 'floor';
    const deferExpensiveOperations = isFloor || !isVisible;

    // Safely get data points
    const getDataPoints = useCallback(() => {
        const index = isQuickAnalysis || !isInteractive ? activePosition : currentFrame;
        return dataPoints[index] || dataPoints[dataPoints.length - 1];
    }, [isQuickAnalysis, dataPoints, activePosition, currentFrame, isInteractive]);

    const renderLegendAside = useMemo(() => {
        const data = getDataPoints();
        const keys = _.keys(data)
            .filter((x) => x.startsWith('graph_line'))
            .sort((a, b) => a.localeCompare(b));
        const MAX_LEGENDS = 5; // Maximum for now.

        return _.map(_.take(keys, MAX_LEGENDS), (key, index) => {
            const line = _.find(flattenedLines, (line) => line.id === key);

            const currentValue = data[key]?.current ?? undefined;
            const comparisonValue = data[key]?.comparison ?? undefined;
            const val = formatValue(currentValue, unit as Unit, true);
            const comparisonVal = formatValue(comparisonValue, unit as Unit, true);

            const color = defaultColors[index] ?? defaultColors[0];
            const label = line?.name.value;
            const isValid = line && label && label !== '';
            const isFilteredOut = _.includes(filteredDataKeys, key);

            if (!isValid) {
                return null;
            }

            const hasComparisonValue = showComparisonData && typeof comparisonValue === 'number';

            return (
                <div
                    key={key}
                    role="button"
                    className={css.asideItem({ active: !isFilteredOut })}
                    style={{ borderLeftColor: color }}
                    onClick={() => onClickLegend(key)}
                >
                    <div
                        className={css.asideItemLabel}
                        style={assignInlineVars({
                            [css.indicatorColor]: isFilteredOut ? colors.bluegray[500] : color,
                        })}
                    >
                        <span className={typography({ variant: 'h4' })}>{label}</span>
                    </div>
                    <div className={cn([css.asideItemText, typography({ variant: 'h2' })])}>
                        <span className={css.ghost}></span>
                        <span className={css.value({ size: hasComparisonValue ? 'small' : 'large' })}>
                            <span>{val}</span>{' '}
                            {hasComparisonValue && <span className={css.comparisonValue}>({comparisonVal})</span>}
                        </span>
                    </div>
                </div>
            );
        });
    }, [unit, flattenedLines, getDataPoints, filteredDataKeys, onClickLegend, showComparisonData]);

    if (!Array.isArray(dataPoints) || !dataPoints.length) {
        return (
            <div className={css.root({ device })}>
                <div className={css.fallback}>
                    <span
                        className={typography({
                            variant: device === 'floor' ? 'h2' : 'h3',
                        })}
                    >
                        {localize('error_message.no_data_available')}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className={css.root({ device })} ref={ref}>
            <ResponsiveContainer minHeight={400}>
                <ComposedChart
                    data={dataPoints}
                    height={400}
                    defaultShowTooltip={false}
                    margin={{ top: 24, bottom: 40, left: 24, right: 24 }}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    {renderXAxis({
                        isQuickAnalysis,
                        segmentation,
                        axisKey: unit,
                    })}
                    {_.map(flattenedLines, (line, index) => {
                        const axis = line?.axis || 'x';
                        const lineIsHidden = _.includes(filteredDataKeys, line.id as string);
                        const color = line.metadata.color ?? defaultColors[index];
                        const name = line.name ? line.name.value : axis ? axis.toUpperCase() : line.id;

                        const thickness = line.metadata.line_thickness ?? 2;

                        return (
                            <Fragment key={line.id}>
                                {/* Current line */}
                                <Line
                                    key={`line_${line.id}_current`}
                                    dataKey={(dataPoint) => dataPoint[line.id]?.current}
                                    yAxisId={line.unit}
                                    stroke={color}
                                    strokeWidth={thickness}
                                    name={`${name} (Current)`}
                                    activeDot={false}
                                    dot={false}
                                    isAnimationActive={false}
                                    type="monotone"
                                    connectNulls
                                    hide={lineIsHidden}
                                />

                                {/* Comparison line */}
                                {showComparisonData && (
                                    <Line
                                        key={`line_${line.id}_comparison`}
                                        dataKey={(dataPoint) => dataPoint[line.id]?.comparison}
                                        yAxisId={line.unit}
                                        stroke={color}
                                        strokeWidth={thickness / 2}
                                        strokeDasharray="5 5"
                                        name={`${name} (Comparison)`}
                                        opacity={0.8}
                                        activeDot={false}
                                        dot={false}
                                        isAnimationActive={false}
                                        type="monotone"
                                        connectNulls
                                        hide={lineIsHidden}
                                    />
                                )}

                                {/* render Apex dots */}
                                {!lineIsHidden && apex[line.id] && (
                                    <ReferenceDot
                                        x={apex[line.id].frame}
                                        y={apex[line.id].value}
                                        r={6}
                                        fill="white"
                                        strokeWidth={2}
                                        stroke={color}
                                        yAxisId={line.unit}
                                    />
                                )}

                                {/* render Transition dots */}
                                {!lineIsHidden && transition[line.id] && (
                                    <ReferenceDot
                                        x={transition[line.id].frame}
                                        y={transition[line.id].value}
                                        r={6}
                                        fill="white"
                                        strokeWidth={2}
                                        stroke={color}
                                        yAxisId={line.unit}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                    <YAxis
                        key={unit}
                        yAxisId={unit}
                        orientation="left"
                        interval="equidistantPreserveStart"
                        width={28}
                        tickCount={8}
                        dx={-10 / 2}
                        stroke={colors.bluegray[400]}
                        fontSize={10}
                        fontWeight={500}
                        tickLine={false}
                        axisLine={false}
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={(val: number) => formatValue(val, unit as Unit, true) ?? ''}
                    />
                    {isFloor && (
                        <Legend
                            wrapperStyle={{
                                bottom: 4,
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                            }}
                            content={({ payload }) => payload && renderLegend(payload as LegendPayload[])}
                        />
                    )}
                    {!deferExpensiveOperations && (
                        <ReferenceLine yAxisId={unit} x={currentFrame} stroke={colors.blue[500]} strokeWidth={2} />
                    )}
                </ComposedChart>
            </ResponsiveContainer>
            {!isFloor && <div className={css.aside}>{renderLegendAside}</div>}
        </div>
    );
}
