import { useCallback, useEffect, useMemo } from 'react';
import { createWithEqualityFn } from 'zustand/traditional';
import { subscribeWithSelector } from 'zustand/middleware';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

import type { Corridor, IVideo } from '@core';
import type { GlobalStoreState } from './globalStore.types';
import { isFiniteNumber, Device } from '@core';
import { UIComponentValues } from '../../components/UIComponents/UIComponent.types';
import { useUserSettingsStore } from '../userSettingsStore';
import { useRelaySocketStore } from '../relaySocketStore';
import { useActivityContext } from '../../utils/contexts/ActivityContext';

const emptyReplay = {
    activeId: null,
    videos: [],

    onReplayEnded: () => {},
};

/**
 * Important guidelines for Global Store
 * ------------------------------------------
 *
 * This is for storing application-wide state that reflects user interactions.
 * e.g. non-persistent UI toggles and preferences.
 *
 * ❌ Don't store database query results here.
 */

export const useGlobalStore = createWithEqualityFn<GlobalStoreState>()(
    subscribeWithSelector((set, get) => ({
        activeSwingID: null,
        comparisonSwingID: null,
        loadingSpinnerVisible: false,
        activePosition: 1,
        currentFrame: 0,
        activityStats: [],
        activeAnnotation: null,
        scrubberProgress: 0,
        showComparison: false,
        forceHideSkeleton: false,
        lastBeastStatus: 'IDLE',
        instantReplay: emptyReplay,
        clubs: [],
        corridors: [],
        isAnalyzing: false,
    })),
);

export const triggerInstantReplayByID = (id: number, videos: IVideo[]) => {
    const resetReplay = () => {
        useGlobalStore.setState({
            instantReplay: emptyReplay,
        });
    };

    useGlobalStore.setState({
        instantReplay: {
            activeId: id,
            videos,
            onReplayEnded: resetReplay,
        },
    });
};

export const setActiveSwingByID = (id: number) => {
    const activeSwingId = useGlobalStore.getState().activeSwingID;

    if (activeSwingId !== id) {
        // When another swing is selected, reset the scrubber and positional state.
        useGlobalStore.setState({
            scrubberProgress: 0,
            currentFrame: 0,
            activePosition: 1,
            activeSwingID: id,
        });
    }
};

export const setComparisonSwingByID = (id: number | null) => {
    if (!isFiniteNumber(id) || id < 0) {
        return useGlobalStore.setState({ showComparison: false, comparisonSwingID: null });
    }

    return useGlobalStore.setState({
        showComparison: true,
        comparisonSwingID: id,
    });
};

/**
 * Returns an observable to be consumed by UIComponent implementations.
 * It holds most of the values that are used by UIComponents + some setters.
 */
export const useUIComponentValues = () => {
    // Observables:
    // https://rxjs.dev/guide/overview

    const [corridor, showCorridorOn] = useUserSettingsStore(
        (state) => [state.activeCorridor, state.userSettings?.showCorridorOn || []] as const,
        ([prev, prevShowOn], [next, nextShowOn]) =>
            (prev?.id || null) === (next?.id || null) && _.isEqual(prevShowOn, nextShowOn),
    );
    const syncedShowCorridorOn = useRelaySocketStore((state) => state.payload?.userSettings?.showCorridorOn);

    const { swings, activityId } = useActivityContext();

    const [activeSwingID, comparisonSwingID, showComparison, pPosition] = useGlobalStore(
        (state) => [state.activeSwingID, state.comparisonSwingID, state.showComparison, state.activePosition] as const,
        (prev, next) => prev[0] === next[0] && prev[1] === next[1] && prev[2] === next[2] && prev[3] === next[3],
    );

    const setActive = useCallback((values: Partial<UIComponentValues>) => {
        if (values.swing?.id) setActiveSwingByID(values.swing.id);
        if (values.comparisonSwing?.id) setComparisonSwingByID(values.comparisonSwing.id);
    }, []);

    // $ postfix is a naming convention for observables
    const values$ = useMemo(() => {
        // Later on, subject$ should be derived from other observables
        const subject$ = new BehaviorSubject<UIComponentValues>({});
        const setValues = (values: UIComponentValues) => {
            subject$.next({ ...subject$.value, ...values });
            setActive(values);
        };
        subject$.next({ setValues });
        return subject$;
    }, [setActive]);

    useEffect(() => {
        const swingID =
            isFiniteNumber(activeSwingID) && activeSwingID > 0
                ? activeSwingID
                : values$.value.swing?.id ?? _.maxBy(swings, (s) => s.id)?.id; // select latest swing by default

        const swing = swingID ? _.find(swings, (a) => a.id === swingID) : undefined;

        const comparisonSwingIDToFind =
            showComparison && isFiniteNumber(comparisonSwingID) && comparisonSwingID > 0
                ? comparisonSwingID
                : values$.value.comparisonSwing?.id || null;
        const comparisonSwing =
            showComparison && comparisonSwingIDToFind
                ? _.find(swings, (a) => a.id === comparisonSwingIDToFind)
                : undefined;

        const corridorPerDevice = corridor
            ? _.transform(
                  syncedShowCorridorOn || showCorridorOn,
                  (acc, device) => {
                      acc[device] = corridor;
                  },
                  {} as { [device in Device]: Corridor },
              )
            : undefined;

        const newValues: UIComponentValues = {
            ...values$.value,
            allSwings: swings,
            swing,
            comparisonSwing,
            corridorPerDevice,
        };

        if (_.isEqual(newValues, values$.value)) return;

        setActive({ swing, comparisonSwing });

        values$.next(newValues);
    }, [
        swings,
        activeSwingID,
        comparisonSwingID,
        showComparison,
        pPosition,
        values$,
        corridor,
        showCorridorOn,
        syncedShowCorridorOn,
        activityId,
        setActive,
    ]);

    return values$;
};

export const useSelectedAnalysis = (latest = false) => {
    const { swings } = useActivityContext();
    const swingID = useGlobalStore((state) => state.activeSwingID);

    // Either get the latest (newest) swing, or the active swing.
    let activeSwing;
    if (latest) {
        activeSwing = swings[0];
    } else {
        activeSwing = _.find(swings, (s) => s.id === swingID);
    }

    if (!activeSwing) return null;

    return activeSwing?.fullAnalysis ?? activeSwing?.quickAnalysis;
};

export const useAnalysisByID = (id: number | null) => {
    const { swings } = useActivityContext();

    if (typeof id !== 'number') {
        return null;
    }

    const activeSwing = _.find(swings, (s) => s.id === id);

    if (!activeSwing) return null;

    return activeSwing?.fullAnalysis ?? activeSwing?.quickAnalysis;
};
